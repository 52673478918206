import { SET_UNSAVED_CHANGES, CLEAR_UNSAVED_CHANGES } from '../constants/unsavedChangesConstants';

export function setUnsavedChanges(bool) {
    return {
        type: SET_UNSAVED_CHANGES,
        payload: bool,
    };
}

export function clearUnsavedChanges() {
    return {
        type: CLEAR_UNSAVED_CHANGES,
    };
}
