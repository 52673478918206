import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, makeShineTheme } from 'shine-ui-react';

const theme = makeShineTheme();
const Provider = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Provider.propTypes = {
    children: PropTypes.any,
};

export default Provider;
