import firebase from 'firebase/app';
import 'firebase/functions';

const useProductionDatabase = process.env.REACT_APP_DATABASE_ENVIRONMENT === 'production';

const config = useProductionDatabase
    ? {
        apiKey: 'AIzaSyCX0FP3RJ2IQiQtSWhprbqTM5wlrG6inWk',
        authDomain: 'shine-etsy.firebaseapp.com',
        databaseURL: 'https://shine-etsy.firebaseio.com',
        projectId: 'shine-etsy',
        storageBucket: 'shine-etsy.appspot.com',
        messagingSenderId: '237731525460',
        appId: '1:237731525460:web:826f85f0162e7d44',
    } : {
        apiKey: 'AIzaSyAG3pRR_GCb9w4u6GRHSeFGLaezKSdhU_k',
        authDomain: 'shine-etsy-dev.firebaseapp.com',
        databaseURL: 'https://shine-etsy-dev.firebaseio.com',
        projectId: 'shine-etsy-dev',
        storageBucket: 'shine-etsy-dev.appspot.com',
        messagingSenderId: '1070570602189',
        appId: '1:1070570602189:web:1cecd59ef394a625',
    };

const fire = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
// fire.functions().useFunctionsEmulator('http://localhost:5000');
export default fire;
