import { ShineOrders } from 'shine-products-v2';
import sortBy from 'lodash/sortBy';
import { setError } from './errorsActions';
import fire from '../fire';

import * as CONSTANTS from '../constants/orderConstants';

// const {
//     REQUEST_ORDER,
//     UPDATE_ORDER,
//     CLEAR_ORDER,
// } = '../constants/orderConstants';

const sendPersonalizationConfirmation = fire.functions().httpsCallable('sendPersonalizationConfirmation');

function requestOrderFromDatabase(orderId) {
    const get = fire.functions().httpsCallable('order');
    return get(orderId);
}
function submitOrderToDatabase(order) {
    const submit = fire.functions().httpsCallable('submit');
    return submit(JSON.parse(JSON.stringify(order)));
    // return Promise.reject(new Error('Failure')); for testing
}

export function updateOrder(order_params) {
    const order = ShineOrders.instance().make(order_params);

    // we need to make sure that product #1 is not a sample
    order.products = sortBy(order.products, ({ product_type }) => !!product_type.match(/sample/i));

    return {
        type: CONSTANTS.UPDATE_ORDER,
        payload: order,
    };
}

export function requestOrder(orderId) {
    if (!orderId) {
        if (window.Cypress) {
            return { type: '' };
        }
        return { type: CONSTANTS.CLEAR_ORDER };
    }
    return function requestOrderThunk(dispatch) {
        dispatch({ type: CONSTANTS.REQUEST_ORDER });
        return requestOrderFromDatabase(orderId)
            .then(({ data }) => {
                dispatch(updateOrder(data));
            });
    };
}

export function submitOrder(order) {
    return function submitOrderThunk(dispatch) {
        return submitOrderToDatabase(order)
            .then(({ data }) => {
                dispatch(updateOrder(data));
                sendPersonalizationConfirmation(order.id);
            }).catch((error) => {
                // Getting the Error details.
                const { message } = error;
                dispatch(setError(message));
            });
    };
}

export function clearOrder() {
    return {
        type: CONSTANTS.CLEAR_ORDER,
    };
}
