import {
    REQUEST_ORDER,
    UPDATE_ORDER,
    CLEAR_ORDER,
} from '../constants/orderConstants';

const initialState = {};

const orderReducer = (state = initialState, action) => {
    console.log(action.payload);
    switch (action.type) {
        case REQUEST_ORDER:
            return 'loading';
        case UPDATE_ORDER:
            return action.payload;
        case CLEAR_ORDER:
            return initialState;
        default:
            return state;
    }
};

export default orderReducer;
