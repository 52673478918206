import {
    createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';

import orderReducer from './orderReducer';
import unsavedChangesReducer from './unsavedChangesReducer';
import errorsReducer from './errorsReducer';

const initialState = {
    order: 'loading',
    unsavedChanges: false,
};

// Create store with reducers and initial state
const rootReducer = combineReducers({
    order: orderReducer,
    unsavedChanges: unsavedChangesReducer,
    errors: errorsReducer,
});

export const store = process.env.NODE_ENV === 'production'
    ? createStore(
        rootReducer, initialState,
        compose(applyMiddleware(thunk)),
    )
    : createStore(
        rootReducer, initialState,
        compose(
            applyMiddleware(thunk),
            // eslint-disable-next-line
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
        ),
    );
export default store;
