import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loader } from 'shine-ui-react';
import { withRouter } from 'react-router-dom';
import Unauthorized from './pages/Unauthorized';
import Submitted from './pages/Submitted';
import { requestOrder } from './actions/orderActions';

const dev = process.env.REACT_APP_DATABASE_ENVIRONMENT !== 'production' && !window.Cypress;

export const UnroutedCustomerIntercept = (props) => {
    const loading = props.loading;
    const params = new URLSearchParams(props.location.search);
    const orderId = params.get('orderId');

    useEffect(() => {
        if (loading) {
            props.requestOrder(orderId);
        }
    });

    let display = null;

    if (loading) {
        display = <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: 0.5,
        }}>
            <Loader size='md'/>
        </div>;
    } else if (!isUndefined(props.order.id)) {
        if (props.order.status === 'hold' || dev) {
            display = props.children;
        } else {
            display = <Submitted />;
        }
    } else {
        display = <Unauthorized/>;
    }

    return <div>{display}</div>;
};

UnroutedCustomerIntercept.defaultProps = {};

UnroutedCustomerIntercept.propTypes = {
    children: PropTypes.any,
    order: PropTypes.any.isRequired,
    match: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
    location: PropTypes.any.isRequired,
    requestOrder: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = ({ order }) => ({
    order: order === 'loading' ? {} : order,
    loading: order === 'loading',
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    requestOrder,
}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnroutedCustomerIntercept));
