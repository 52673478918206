import React from 'react';

function asyncComponent(importComponent) {
    class AsyncComponent extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                component: null,
            };
        }

        async componentDidMount() {
            const { default: component } = await importComponent();
            this.setState({
                component,
            });
        }

        render() {
            const C = this.state.component;

            return <div className={'async-component-wrapper'} style={{ opacity: C ? 1 : 0 }}>
                { C && <C {...this.props} /> }
            </div>;
        }
    }

    return AsyncComponent;
}
export default asyncComponent;
