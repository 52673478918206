import React from 'react';
import { Redirect, Switch, Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { CssBaseline } from 'shine-ui-react';
import asyncComponent from './AsyncComponent';
import CustomerIntercept from './CustomerIntercept';

const Cart = asyncComponent(() => import('./pages/Cart'));
const EditProductForm = asyncComponent(() => import('./pages/EditProduct'));
const NewProductForm = asyncComponent(() => import('./pages/NewProduct'));
const Products = asyncComponent(() => import('./pages/Products'));
const ProductDesigns = asyncComponent(() => import('./pages/ProductDesigns'));
const Checkout = asyncComponent(() => import('./pages/Checkout'));
const PriceCalculator = asyncComponent(() => import('./pages/PriceCalculator'));

function App() {
    return (
        <div>
            <CssBaseline />
            <Switch>
                <Route exact path="/pricing" component={PriceCalculator} />
                <CustomerIntercept>
                    <Switch>
                        <Route exact path="/shop" component={Products} />
                        <Route exact path="/shop/:productType" component={ProductDesigns} />
                        <Route exact path="/new/:productType" component={NewProductForm} />
                        <Route exact path="/edit/:index" component={EditProductForm} />
                        <Route exact path="/cart" component={Cart} />
                        <Route exact path="/checkout" component={Checkout} />
                        <Route exact path="/etsy" render={() => <Redirect to="/edit/0" />} />
                        <Route render={() => <Redirect to="/cart" />} />
                    </Switch>
                </CustomerIntercept>
            </Switch>
        </div>
    );
}

export default withRouter(App);
