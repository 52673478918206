import { SET_UNSAVED_CHANGES, CLEAR_UNSAVED_CHANGES } from '../constants/unsavedChangesConstants';

const initialState = false;

const unsavedChangesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_UNSAVED_CHANGES:
            return initialState;
        case SET_UNSAVED_CHANGES:
            return action.payload;
        default:
            return state;
    }
};

export default unsavedChangesReducer;
