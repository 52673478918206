import React, { useState } from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import {
    withStyles,
    Typography,
    ShineLogo,
    Box,
    Button,
} from 'shine-ui-react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLock,
    faPhone,
    faEnvelope,
    faGripVertical,
    faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';
import { setUnsavedChanges, clearUnsavedChanges } from '../actions/unsavedChangesActions';

const styles = (theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    header: {
        width: '100%',
        height: 140,
        position: 'relative',
        marginBottom: '2rem',
        '& $container': {
            height: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
            '& > div': {
                zIndex: 1,
                backgroundColor: 'white',
                padding: '0px 0.6rem',
            },
        },
        '& $greyLine': {
            bottom: 37,
            zIndex: 0,
        },
    },
    logo: {
        position: 'relative',
        top: '-27px',
    },
    link: {
        position: 'relative',
        fontWeight: 500,
        bottom: 27,
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
    body: {
        width: '100%',
        minHeight: 600,
    },
    footer: {
        width: '100%',
        position: 'relative',
        '& $greyLine': {
            top: 0,
            zIndex: 0,
        },
    },
    container: {
        maxWidth: 1160,
        margin: [0, 'auto'],
        '@media (max-width: 864px)': {
            padding: [0, '2rem'],
        },
        '@media (max-width: 768px)': {
            padding: [0, '1rem'],
        },
    },
    contact: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        padding: ['1.5rem', 0],
        '@media (min-width: 768px)': {
            justifyContent: 'flex-end',
            '& > div': {
                width: 'auto',
            },
        },
    },
    contactItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        margin: 8,
        '& svg': {
            height: 11,
            marginRight: 6,
        },
    },
    boxContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        columnGap: '3rem',
        rowGap: '2rem',
    },
    copyright: {
        fontSize: '0.6rem',
        padding: ['1rem', 0, 0],
        width: '100%',
        textAlign: 'center',
    },
    greyLine: {
        position: 'absolute',
        left: 0,
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey.light}`,
    },
    mobile: {
        display: 'block',
        position: 'absolute',
        top: '1rem',
        '@media (min-width: 601px)': {
            display: 'none',
        },
    },
    shop: {
        left: '1rem',
    },
    cart: {
        right: '1rem',
    },
    confirmationModal: {
        backgroundColor: 'white',
        transition: 'opacity 0.7s',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
        minHeight: 300,
        width: '33%',
        minWidth: 300,
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    removeButton: {
        backgroundColor: 'red',
        marginTop: '1rem',
        '&:hover': {
            backgroundColor: 'darkred',
        },
    },
    cancelButton: {
        backgroundColor: theme.palette.grey.light,
        marginTop: '1rem',
        '&:hover': {
            backgroundColor: theme.palette.grey.medium,
        },
    },
});

const mapStateToProps = ({ unsavedChanges }) => ({ unsavedChanges });
const mapDispatchToProps = (dispatch) => bindActionCreators({
    setUnsavedChanges,
    clearUnsavedChanges,
}, dispatch);

const UnstyledLink = (props) => {
    const {
        classes,
        to,
        children,
        unsavedChanges,
        history,
    } = props;

    const [prompt, setPrompt] = useState(false);

    const handleNavigation = () => {
        props.clearUnsavedChanges();
        history.push(to);
    };

    const onClick = () => {
        if (unsavedChanges) {
            setPrompt(true);
        } else {
            handleNavigation();
        }
    };

    return (
        <React.Fragment>
            <span data-cy={snakeCase(`link to ${to}`)} onClick={onClick}>{children}</span>
            {prompt && <Modal open={prompt}>
                <div className={classes.confirmationModal} data-cy='unsaved_changes_modal'>
                    <div>
                        <Typography align='center' variant='h4' gutterBottom>You have unsaved changes!</Typography>
                        <Typography align='center' component='p'>Are you sure you want to leave this page?</Typography>
                    </div>
                    <div className={classes.buttonGroup} data-cy='unsaved_changes_button_group'>
                        <Button
                            primary
                            className={classes.removeButton}
                            data-cy='cancel_unsaved_changes'
                            onClick={() => setPrompt(false)}>
                            Cancel
                        </Button>
                        <Button
                            primary
                            className={classes.cancelButton}
                            data-cy='confirm_unsaved_changes'
                            onClick={handleNavigation}>
                            Yes
                        </Button>
                    </div>
                </div>
            </Modal>}
        </React.Fragment>
    );
};

UnstyledLink.defaultProps = {
    classes: {},
};

UnstyledLink.propTypes = {
    classes: PropTypes.any,
    to: PropTypes.string.isRequired,
    children: PropTypes.any,
    unsavedChanges: PropTypes.bool.isRequired,
    history: PropTypes.any.isRequired,
    clearUnsavedChanges: PropTypes.func.isRequired,
};

const Link = withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UnstyledLink)));

export const UnstyledShineEtsyPageWrapper = (props) => {
    const { classes, includeNavigation } = props;
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.greyLine} />
                <div className={classes.container}>
                    {includeNavigation
                        && <div
                            className={clsx(classes.mobile, classes.shop)}
                            data-cy='mobile'>
                            <Link to='/shop'>
                                <FontAwesomeIcon icon={faGripVertical} />
                            </Link>
                        </div>
                    }
                    {includeNavigation
                        && <div
                            className={clsx(classes.mobile, classes.cart)}
                            data-cy='mobile'>
                            <Link to='/cart'>
                                <FontAwesomeIcon icon={faShoppingCart} />
                            </Link>
                        </div>
                    }
                    {includeNavigation
                        && <div
                            className={classes.link}
                            data-cy='desktop'>
                            <Link to='/shop'>
                                <Typography underlined variant='link'>SHOP</Typography>
                            </Link>
                        </div>
                    }

                    <div className={classes.logo}>
                        <ShineLogo component='div' width={237} />
                    </div>

                    {includeNavigation
                        && <div
                            className={classes.link}
                            data-cy='desktop'>
                            <Link to='/cart'>
                                <Typography underlined variant='link'>CART</Typography>
                            </Link>
                        </div>
                    }
                </div>
            </div>
            <div className={clsx(classes.body, classes.container)}>
                {props.children}
            </div>
            <div className={classes.footer}>
                <div className={classes.greyLine} />
                <div className={classes.container}>
                    <div className={classes.contact}>
                        <div className={classes.contactItem}>
                            <div>
                                <FontAwesomeIcon icon={faPhone} transform='flip-h' />
                            </div>
                            <div>
                                <a href='tel:+18559734433'><Typography variant='subtitle2'>{' (855) 973-4433'}</Typography></a>
                            </div>
                        </div>
                        <div className={classes.contactItem}>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <a href='mailto:hello@shineweddinginvitations.com'>
                                <Typography variant='subtitle2'>EMAIL US</Typography>
                            </a>
                        </div>
                    </div>
                    <div className={classes.boxContainer}>
                        <Box
                            className={classes.footerBox}
                            header={<Typography>YOUR WEDDING DAY STARTS AT SHINE</Typography>}
                            body={
                                <Typography>
                                    Your wedding day starts with your invitation.
                                    Make an impression with high quality paper,
                                    state of the art printing, and clean,
                                    modern and elegant design.
                                </Typography>
                            }
                        />

                        <Box
                            className={classes.footerBox}
                            header={<Typography>SHOP WITH CONFIDENCE</Typography>}
                            body={
                                <Typography>
                                    Satisfaction Guaranteed • Safe & Secure Checkout <FontAwesomeIcon icon={faLock} className={classes.lockIcon} />
                                </Typography>
                            }
                        />
                    </div>
                    <div className={classes.copyright}>
                        <Typography align='center' variant='caption' className={classes.copyright}>
                            © 2024. SHINE WEDDING INVITATIONS | LUXURY WEDDING INVITATIONS FOR MODERN WEDDINGS. | ALL RIGHTS RESERVED.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

UnstyledShineEtsyPageWrapper.defaultProps = {
    classes: {},
    children: null,
    includeNavigation: true,
};

UnstyledShineEtsyPageWrapper.propTypes = {
    classes: PropTypes.any,
    children: PropTypes.any,
    includeNavigation: PropTypes.bool,
};

export default withStyles(styles)(UnstyledShineEtsyPageWrapper);
