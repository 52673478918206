import { SET_ERRORS, CLEAR_ERRORS } from '../constants/errorsConstants';

const initialState = null;

const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ERRORS:
            return initialState;
        case SET_ERRORS:
            return action.payload;
        default:
            return state;
    }
};

export default errorsReducer;
