import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
    withStyles,
    Box,
    Divider,
    Typography,
} from 'shine-ui-react';
import clsx from 'clsx';
import ShineEtsyPageWrapper from '../components/ShineEtsyPageWrapper';

const styles = () => ({
    root: {
        maxWidth: 800,
        margin: [0, 'auto'],
    },
    divider: {
        margin: ['2rem', 0],
    },
    contact: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        padding: ['1.5rem', 0],
    },
    contactItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        margin: 8,
        '& svg': {
            height: 11,
            marginRight: 6,
        },
        '@media (min-width: 768px)': {
            width: '45%',
        },
    },
});

export const UnstyledSubmittedPage = (props) => {
    const { classes, className } = props;
    return (
        <ShineEtsyPageWrapper includeNavigation={false}>
            <div className={clsx(classes.root, className)} data-cy='submitted_page'>
                <Box header={
                    <Typography variant="h1">Thank you for your order!</Typography>
                }
                body={
                    <div>
                        {[
                            'We\'ve received your order and a confirmation email has been sent.',
                            'If you don\'t see the email within a few minutes, please check your spam folder and add hello@shineweddinginvitations.com to your contact list.',
                        ].map((str) => <Typography gutterBottom align='center' key={str}>{str}</Typography>)}

                        <Divider className={classes.divider} />
                        <Typography gutterBottom align='center'><strong>What happens next?</strong></Typography>
                        {[
                            'We will be in touch by email in 2 business days with your personalized proofs.',
                            'If you do not see the proof email, please check your spam folder.',
                        ].map((str) => <Typography align='center' key={str}>{str}</Typography>)}
                    </div>
                } />
                <Divider className={classes.divider} hidden/>
                <Box header={
                    <Typography variant="h1">More Questions?</Typography>
                }
                body={
                    <div>
                        <Typography gutterBottom align='center'>Get in touch with us and we will be happy to help you!</Typography>
                        <div className={classes.contact}>
                            <div className={classes.contactItem}>
                                <div>
                                    <FontAwesomeIcon icon={faPhone} transform="flip-h"/>
                                </div>
                                <div>
                                    <a href='tel:+18009743086'><Typography variant='subtitle2'> 1-800-974-3086</Typography></a>
                                </div>
                            </div>
                            <div className={classes.contactItem}>
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <a href='mailto:hello@shineweddinginvitations.com'>
                                    <Typography variant='subtitle2'>EMAIL US</Typography>
                                </a>
                            </div>
                        </div>
                    </div>
                } />
                <Divider className={classes.divider} hidden/>
            </div>
        </ShineEtsyPageWrapper>
    );
};

UnstyledSubmittedPage.defaultProps = {
    classes: {},
};

UnstyledSubmittedPage.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.string,
};

export default withStyles(styles)(UnstyledSubmittedPage);
