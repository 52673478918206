import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
    withStyles,
    Box,
    Divider,
    Typography,
} from 'shine-ui-react';
import clsx from 'clsx';
import ShineEtsyPageWrapper from '../components/ShineEtsyPageWrapper';

const styles = () => ({
    root: {
        maxWidth: 800,
        margin: [0, 'auto'],
    },
    divider: {
        margin: ['2rem', 0],
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
    },
    image: {
        width: 200,
        height: 200,
    },
    contact: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        padding: ['1.5rem', 0],
    },
    contactItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        margin: 8,
        '& svg': {
            height: 11,
            marginRight: 6,
        },
        '@media (min-width: 768px)': {
            width: '45%',
        },
    },
});

export const UnstyledUnauthorizedPage = (props) => {
    const { classes, className } = props;
    return (
        <ShineEtsyPageWrapper includeNavigation={false}>
            <div className={clsx(classes.root, className)} data-cy='unauthorized_page'>
                <Box header={
                    <Typography variant='h1'>Your cart is empty!</Typography>
                }
                body={
                    <React.Fragment>
                        <div className={classes.centered}>
                            <div className={classes.image}>
                                <a href='https://www.etsy.com/shop/ShineInvitations'>
                                    <img
                                        width='200'
                                        alt='Shine Wedding Invitations'
                                        src={[
                                            'https://firebasestorage.googleapis.com/v0',
                                            '/b/shineordermanager.appspot.com/o/email_template_images%2Flogo.jpg',
                                            '?alt=media&token=3c20b1c0-fb44-4afb-9eb7-20b93b4eda12',
                                        ].join('')}
                                    />
                                </a>
                            </div>
                        </div>
                        <Typography align='center'>Purchase your Etsy deposit here to continue shopping.</Typography>
                    </React.Fragment>
                } />
                <Divider className={classes.divider} hidden/>
                <Box header={
                    <Typography variant='h1'>Got Questions?</Typography>
                }
                body={
                    <div>
                        <Typography gutterBottom align='center'>Get in touch with us and we will be happy to help you!</Typography>
                        <div className={classes.contact}>
                            <div className={classes.contactItem}>
                                <div>
                                    <FontAwesomeIcon icon={faPhone} transform="flip-h"/>
                                </div>
                                <div>
                                    <a href='tel:+18009743086'><Typography variant='subtitle2'> 1-800-974-3086</Typography></a>
                                </div>
                            </div>
                            <div className={classes.contactItem}>
                                <FontAwesomeIcon icon={faEnvelope}/>
                                <a href='mailto:hello@shineweddinginvitations.com'>
                                    <Typography variant='subtitle2'>EMAIL US</Typography>
                                </a>
                            </div>
                        </div>
                    </div>
                } />
                <Divider className={classes.divider} hidden/>
            </div>
        </ShineEtsyPageWrapper>
    );
};

UnstyledUnauthorizedPage.defaultProps = {
    classes: {},
};

UnstyledUnauthorizedPage.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.string,
};

export default withStyles(styles)(UnstyledUnauthorizedPage);
