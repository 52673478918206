import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from 'shine-ui-react';

const styles = () => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100%',
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity 0.2s ease-in',
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
});

export const UnstyledModal = (props) => {
    const { classes, children, open } = props;
    const [visible, updateVisible] = useState(false);

    useEffect(() => {
        updateVisible(true);
        return () => updateVisible(false);
    }, [open]);

    return ReactDOM.createPortal(
        <div className={clsx(classes.root, { [classes.visible]: visible })}>
            {children}
        </div>,
        document.getElementById('root'),
    );
};

UnstyledModal.defaultProps = {
    classes: {},
};

UnstyledModal.propTypes = {
    classes: PropTypes.any,
    children: PropTypes.any,
};

export default withStyles(styles)(UnstyledModal);
